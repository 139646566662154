@import "../../../../assests/styles/colors.scss";

.home-heading{
    display: flex;
    align-items: flex-end;
    padding-top: 13.25rem;
    position: relative;

    .left-section{
        flex: 1;
        .desc{
            margin-top: 1.5rem;
        }

        .action-row{
            display: flex;
            align-items: center;
            margin-top: 3.625rem;
            gap: 1.75rem;
            margin-bottom: 1rem;

            .social-icons{
                display: flex;
                gap: 1.25rem;
                align-items: center;

                .icon{
                    width: 2.5rem;
                    height: 2.5rem;
                    border-radius: 50%;
                    background-color: var(--theme_blue);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;

                    &:hover{
                        background-color: var(--theme_blue_hover);
                    }

                    svg{
                        width: 1.25rem;
                        height: 1.25rem;
                        fill:var(--white);
                    }
                }
            }
        }
    }
    .right-section{
        flex: 1;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .scroll-icon{
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    .left-section {
      flex: 4 !important;
    }
    .right-section {
      flex: 3 !important;
    }
  }
  @media only screen and (max-width: 920px) {
    .home-heading{
        flex-direction: column;
        align-items: flex-start;
        padding-top: 7.25rem;
        gap: 1.5rem;

        .left-section{
            text-align: center;
            width: 100%;
            .desc{
                margin-top: 1rem;
            }
            .action-row{
                margin-top: 2.5rem;
                // flex-direction: column;
                // align-items: center;
                justify-content: center;
                gap: 1.5rem;
            }
        }
        .right-section{
            width: 100%;
        }
        .scroll-icon{
            display: flex;
            justify-content: center;
            width: 100%;
            position: absolute;
            bottom: -7rem;

            .scrolling{
                animation: updown 1.5s ease-in-out infinite;
                font-size: 3rem;
                color: var(--theme_blue);
            }
        }
    }
  }

  @keyframes updown {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }