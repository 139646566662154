@import '../../../../assests/styles/colors.scss';

.contact{
    padding-top: 8rem;
    position: relative;
    z-index: 1;

    .contact-form-container{
        position: relative;
        background: var(--light_blue);
        margin: 2rem 5rem;
        display: flex;
        border-radius: 1rem;
        padding: 2.938rem 3.125rem;
        gap: 9.375rem;

        .contact-img{
            position: absolute;
            right: 3.125rem;
            bottom: 7rem;
            width: 41%;
        }

        .contact-form{
            width: 45%;

            .contact-sub{
                margin: 0.5rem 0 1rem 0.3rem;
            }

            .contact-message{
                margin-bottom: 2rem;
            }
        }
    }

}

@media only screen and (max-width: 1160px) {
    .contact-form-container{
        gap: 0 !important;

        .contact-img{
            display: none;
        }
        .contact-form{
            width: 93% !important;
        }
    }

}

@media only screen and (max-width: 760px) {
    .contact-form-container{
        margin: 0 !important;
    }
}