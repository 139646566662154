@import '../../../assests/styles/colors.scss';

.footer-wrapper{
    background: var(--border_blue);
    position: absolute;
    width: 100vw;
    left: 0;
    margin-top: -17rem;
    z-index: 0;

    .main-container{
        padding-top: 19rem;

        .inside-container{
            margin: 0 5rem;

            .contact-row{
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 2rem;

                .contact-card{
                    border-radius: 15px;
                    background: var(--theme_blue_hover);
                    padding: 1rem 2.125rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 2rem;
                    width: 100%;
                    flex: 1;
                    text-decoration: none;
                    min-height: 100px;

                    .card-logo{
                        width: 3.75rem;

                        img{
                            width: 100%;
                            object-fit: scale-down;
                        }
                    }
                    .contact-details{

                        .card-title, .card-content{
                             color: var(--white);
                            //  white-space: nowrap;
                        }
                    }
                }
            }

            .footer-content{
                display: flex;
                margin-top: 3.125rem;
                gap: 2rem;

                .footer-about{
                    flex: 1;
                    .logo{
                        width: 4.813rem;
                        height: 4.813rem;
                        background: var(--theme_blue);
                        border-radius: 50%;

                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .footer-desc{
                        color: var(--white);
                        margin: 0.688rem 0 2rem 0;
                    }
                    .social-icons{
                        display: flex;
                        gap: 1.25rem;
                        align-items: center;
        
                        .icon{
                            width: 2.5rem;
                            height: 2.5rem;
                            border-radius: 50%;
                            background-color: var(--theme_blue);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            transition: all 0.3s ease-in-out;
        
                            &:hover{
                                background-color: var(--theme_blue_hover);
                            }
        
                            svg{
                                width: 1.25rem;
                                height: 1.25rem;
                                fill: var(--white);
                            }
                        }
                    }
                }
                .footer-navigation{
                    flex: 1;
                    display: flex;
                    flex-direction: column;

                    .nav-title{
                        color: var(--white);
                        font-size: 1.625rem;
                        font-weight: 500;
                        margin-bottom: 2rem;
                    }

                    .footer-nav{
                        color: var(--white);
                        cursor: pointer;
                        position: relative;
                        padding-left: 1.5rem;
                        margin-bottom: 0.5rem;

                        &:hover{
                            color: var(--theme_blue);
                        }

                        &::after{
                            content: '';
                            display: block;
                            width: 0.5rem;
                            height: 0.5rem;
                            border-radius: 50%;
                            background: var(--white);
                            position: absolute;
                            bottom: 0.5rem;
                            left: 0rem;
                        }
                    }
                }
                .footer-logo-container{
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .footer-logo{
                        background: var(--theme_blue);
                        border-radius: 50%;
                        width: 275px;
                        height: 275px;

                        img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }

            .copyrights-row{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 2.5rem;
                padding: 2rem 0;
                border-top: 1px solid var(--white);

                .web-name, .copyrights{
                    color: var(--white);
                }
                .copyrights{
                    display: flex;
                    align-items: center;
                    gap: 8px;

                    svg, a{
                        color: var(--white);
                        text-decoration: none;
                    }
                }
            }
        }
        
    

    .navigation-wrapper{
        display: flex;
        align-items: center;
        gap:3.75rem;

        .nav-item{
            color: var(--font_gray);
            font-size: 1.875rem;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &.active , &:hover{
                color: var(--theme_blue);
            }
        }
    }
}
}

@media only screen and (max-width: 1355px) {
    .contact-row{
        flex-direction: column;

        .contact-card{
            width: calc( 100% - 4.5rem ) !important;
        }
    }
    .footer-logo-container{
        display: none !important;
      }
  }

@media only screen and (max-width: 1100px) {
    .footer-content{
        gap: 4rem;
        .footer-logo-container{
            display: none;
        }
    }
  }

@media only screen and (max-width: 920px) {
    .footer-content{
        flex-direction: column;
    }
  }

  @media only screen and (max-width: 760px) {
    .inside-container{
        margin: 0 !important;
    }
}