:root{
    --background : #FFFFFF;
    --theme_blue_light : #E2F5FB;
    --theme_blue : hsl(195, 74%, 57%);
    --theme_blue_hover : #215D72;
    --font_gray : #5C5C5C;
    --h1 : #252B42;
    --h2 : #17414F;
    --font_gray_regular : #737373;
    --border_blue: #17414F;
    --white : #FFFFFF;
    --font_bold : #454545;
    --light_blue : #BCE7F5;
    --dark_font : #000000;
    --nav_font : #5C5C5C;
    --error_red : #c61b1b;
    --error_font_red : #c61b1b;

}

[data-theme='dark'] {
    --background : #282828;
    --theme_blue_light : #17414F;
    --nav_font : #FFFFFF;
    --h1 : #FFFFFF;
    --h2 : #FFFFFF;
    --font_bold : #FFFFFF;
    --font_gray_regular : #b1b1b1;
    --light_blue : #215D72;
    --error_font_red : #FFFFFF;
}