@import '../../../assests/styles/colors.scss';

.header-wrapper{
    background: var(--theme_blue_light);
    position: fixed;
    width: 100vw;
    top: 0;
    height: 7.5rem;
    z-index: 1000;
    box-shadow: 0 7px 21px #00000030;
    left: 0;

    .main-container{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        position: relative;
        
    .logo{
        width: 4.813rem;
        height: 4.813rem;
        background: var(--theme_blue);
        border-radius: 50%;

        img{
            width: 100%;
            height: 100%;
        }
    }

    .mobile-menu-icon {
        position: absolute;
        right: 0;
        cursor: pointer;
        outline: none;
        display: none;
        background: var(--theme_blue);
        padding: 1rem 1rem 0.7rem;
        border-radius: 15px;

        &:hover{
            background: var(--theme_blue_hover);
        }

        svg{
            font-size: 2.5rem;
            color: var(--white);
        }
        * {
          pointer-events: none;
        }
      }

    .navigation-wrapper{
        display: flex;
        align-items: center;
        gap:3.75rem;

        .closeNavIcon{
            display: none;
        }

        .nav-item{
            color: var(--nav_font);
            font-size: 1.675rem;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.3s ease-in-out;

            &.active , &:hover{
                color: var(--theme_blue);
            }

            &.contact{
                display: none;
            }
        }
    }
}
}

@media only screen and (max-width: 920px) {
    .header-wrapper{
        background: transparent;
        box-shadow: none;

        .main-container{
            .mobile-menu-icon {
                display: block;
            }
            .contact-btn, .logo{
                display: none;
            }

            .hide-items {
                transform: translateY(calc(-100% - 1rem));
            }

            .navigation-wrapper{
                transition: 0.3s ease transform;
                background: var(--theme_blue_light);
                padding: 2rem;
                width: 90%;
                max-width: 300px;
                border-radius: 15px;
                position: absolute;
                right: -1px;
                top: 1rem;
                flex-direction: column;
                align-items: flex-start;
                gap: 2rem;
                box-shadow: 0 7px 21px #00000030;

                .nav-item{
                    &.contact{
                        display: contents;
                    }
                }

                .closeNavIcon {
                    display: block;
                    margin: 0 0 0 auto;
                    cursor: pointer;
                    position: absolute;
                    right: 2rem;

                    svg{
                        color: var(--nav_font);
                        font-size: 2rem;
                    }

                    &:hover{
                        svg{
                            color: var(--theme_blue_hover);
                        }
                    }

                    * {
                    pointer-events: none;
                    }
                }
            }
        }
    }
  }

  @media only screen and (max-width: 280px) {
    .header-wrapper{
        .main-container{
            .navigation-wrapper{
                max-width: 280px;
                right: -12px;
            }
        }
    }
  }