@import '../../../../assests/styles/colors.scss';

.portfolio{
    padding-bottom: 12.5rem;
    .portfolio-title{
        text-align: center;
        margin-bottom: 6rem;
    }

    .user-card-wrapper{

        .user-card{
            border-radius: 1rem;
            text-align: center;
            box-shadow: 0 14px 21px #00000030;
            margin-bottom: 3rem;
            padding: 0.8rem 0;

            &:hover{
                box-shadow: 0 14px 21px #00000050;
            }

            img{
                width: 100%;
                height: 16.063rem;
                object-fit: cover;
                border-radius: 1rem 1rem 0 0;
            }

            .user-name{
                margin: 2.063rem 0 0.488rem 0;
                white-space:nowrap;
            }
            .designation{
                color: var(--font_gray_regular);
            }
            .social-icons{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1.375rem;
                padding: 0.888rem 0 1.063rem 0;

                .social-icon{
                    color: var(--theme_blue);
                    cursor: pointer;

                    svg {
                        font-size: 1.5rem;
                    }

                    &:hover{
                        color: var(--theme_blue_hover);
                    }
                }
            }
        }
    }
}

.swiper-button-next, .swiper-button-prev{
    background: var(--theme_blue) !important;
    color: var(--white) !important;
    width: 2rem !important;
    height: 2rem !important;
    padding: 0.5rem !important;
    border-radius: 50% !important;
    cursor: pointer !important;
    box-shadow: 0 5px 5px #00000030 !important;

    &:hover{
        background: var(--theme_blue_hover) !important;
        box-shadow: 0 5px 5px #00000050 !important;
    }

    &::after{
        --swiper-navigation-size: 1rem !important;
    }
}

@media only screen and (max-width: 920px) {
    .user-card{
        img{
            height: 30rem !important;
        }
        .social-icons{
            padding: 0.888rem 0 3.063rem 0 !important;
        }
    }
}
