@import '../../../../assests/styles/colors.scss';

.about-section-wrapper{
    margin-top: 15.938rem;
    background: var(--theme_blue_light);
    border-radius: 18.75rem 0 18.75rem 0;

    .about-section{
        padding: 6.25rem 0;
        display: flex;
        flex-direction: column;
        gap: 4.375rem;
    }
}