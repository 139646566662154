@import '../../../assests/styles/colors.scss';

.about-block-wrapper{
    display: flex;
    align-items: flex-start;
    padding: 0 3.438rem;

    .left-section{
        flex: 4;
        
        img{
            width: 85%;
            height: 100%;
            object-fit: cover;
        }
    }

    .right-section{
        flex: 3;

        .heading {
            margin: 4.375rem 0 1.375rem 0;
        }

        .desc{
            margin-bottom: 3.375rem;
            font-size: 1.2rem;
        }

        .highlight{
            margin-bottom: 1.375rem;
        }

    }

    &.left{
        .left-section{
            order: 2;

            img{
                float: right;
            }
        }

        .right-section{
            order: 1;
        }
    }
}

@media only screen and (max-width: 920px) {
    .about-block-wrapper{
        flex-direction: column;
        gap: 2.5rem;

        .left-section{
            order: 2;
            margin-bottom: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                float: none;
            }
        }

        .right-section{
            order: 1;

            .action-btn{
                float: right;
            }
        }
    }
  }