@import './colors.scss';
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@500&family=Jost:wght@400;500;600&family=Kalam&display=swap');

* {
    font-size: 16px;
    font-family: 'Jost', sans-serif;

    ::-webkit-scrollbar{
        display: none;
    }
}

body {
    margin: 0;
    background: var(--background) !important;
}

.main-container {
    max-width: 1620px;
    width: 90%;
    margin: 0 auto;
  }

.h1 {
    color: var(--h1);
    font-weight: 600;
    font-size: 3.625rem;
    line-height: 5rem;
}

.h2{
    color: var(--h2);
    font-weight: 600;
    font-size: 3rem;
    line-height: 3.25rem;
}

.regular-font {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: var(--font_gray_regular);                          
}

.regular-font-bold {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: var(--font_bold);                          
}

.default-font {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--font_gray_regular);                          
}

.c-input, .c-textarea{
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--font_gray_regular);  
        width: 100%;
        padding: 1rem;
        background-color: var(--background);
        outline: none;
        border: none;
        border-radius: 40px;
        margin-top: 1.25rem;
}

.c-textarea{
    min-height: 130px;
    resize: vertical;
    border-radius: 15px;
}

.input-label{
    font-size: 1.125rem;
    margin-left: 10px;
}

.button-wrapper{
    border-radius: 30px;
    background: var(--theme_blue);
    font-size: 1rem;
    font-weight: 500;
    color:var(--white);
    line-height: 3.125rem;
    padding: 1px 31px 0px;
    cursor: pointer;
    white-space: nowrap;
    width: -moz-fit-content;
    width: fit-content;
    transition: all 0.3s ease-in-out;

    &:hover{
        background: var(--theme_blue_hover);
    }

    &.disabled{
        opacity: 0.5;
        pointer-events: none;
    }

    &.outline{
        background: var(--white);
        border: 1px solid var(--border_blue);
        color: var(--border_blue);

        &:hover{
            border-color: var(--theme_blue);
            color: var(--theme_blue);
        }
    }
}

@media only screen and (max-width: 1024px) {
    * {
        font-size: 14px;
    }
    
  }
@media only screen and (max-width: 480px) {
    * {
        font-size: 12px;
    }

    .h1{
        font-size: 2rem;
        line-height: 3rem;
    }
    .h2{
        font-size: 2.6rem;
    }
    
  }