@import '../../../../assests/styles/colors.scss';

.events{
    padding-bottom: 4.5rem;
    .news-title{
        text-align: center;
        margin-bottom: 6rem;
    }

    .news-card-wrapper{

        .news-card{
            border-radius: 1rem;
            text-align: center;
            box-shadow: 0 14px 21px #00000030;
            margin-bottom: 3rem;

            &.even{
                margin-top: 7rem;
            }

            &:hover{
                box-shadow: 0 14px 21px #00000050;
            }
        }
    }

    
}

.swiper-button-next, .swiper-button-prev{
    background: var(--theme_blue) !important;
    color:var(--white) !important;
    width: 2rem !important;
    height: 2rem !important;
    padding: 0.5rem !important;
    border-radius: 50% !important;
    cursor: pointer !important;
    box-shadow: 0 5px 5px #00000030 !important;

    &:hover{
        background: var(--theme_blue_hover) !important;
        box-shadow: 0 5px 5px #00000050 !important;
    }

    &::after{
        --swiper-navigation-size: 1rem !important;
    }
}

@media only screen and (max-width: 920px) {
    .news-card-wrapper{

        .news-card{

            &.even{
                margin-top: 0 !important;
            }
        }
    }
    .news-card{
        &.even{
            margin-top: 0;
        }
    }
}
