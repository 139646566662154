@import '../../../../assests/styles/colors.scss';

.srilanka-section{
    position: relative;
    padding: 12rem 0 12.5rem 0;

    .top-content{
        display: flex;
        gap: 5.625rem;

        .video-container{
            flex: 1;
            width: 100%;
            height: 28rem;
            border-radius: 16px;
    
            iframe{
                border-radius: 14px;
            }

            .explore{
                display: flex;
                gap: 1.5rem;
                margin-top: 2rem;
                align-items: center;
                color: var(--theme_blue);
                cursor: pointer;

                &:hover{
                    color: var(--theme_blue_hover);
                }
            }
        }
    
        .srilanka-right-section{
            flex: 1;
            
            .section-title{
                margin-bottom: 2rem;
            }
        }
    }

    .link-container-title{
        padding-top: 6.25rem;
        text-align: center;
        margin-bottom: 6rem;
    }

    .link-container{

        .related-card{
            cursor: pointer;
            color: var(--theme_blue);
            display: flex;
            flex-direction: column;
            align-items: center;

            img{
            height: 7rem;
            width: 100%;
            object-fit: scale-down;
            }

            .site-title {
                color: var(--dark_front);
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 920px) {
    .top-content{
        flex-direction: column-reverse;

        .video-container{
            flex: unset !important;
        }

        .section-title{
            text-align: center;
        }
    }
}
